import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import '../css/HomePage.css'
import LoadingComponent from '../components/LoadingComponent';
import UserContext from '../context/UserContext';
import Search from '../svg/Search';
import TacheTout from '../svg/tacheSVG/TacheTout';
import TacheConsultationCentralRisque from '../svg/tacheSVG/TacheConsultationCentralRisque';
import TacheNotificationBanque from '../svg/tacheSVG/TacheNotificationBanque';
import TacheConfirmationPaiementBanque from '../svg/tacheSVG/TacheConfirmationPaiementBanque';
import TacheReceptionDossierBanque from '../svg/tacheSVG/TacheReceptionDossierBanque';
import TacheControleDossierPhysiqueBanque from '../svg/tacheSVG/TacheControleDossierPhysiqueBanque';
import TacheSuiviDossier from '../svg/tacheSVG/TacheSuiviDossier';
import TacheComplementInformationBanque from '../svg/tacheSVG/TacheComplementInformationBanque';
import fileDownload from 'js-file-download';
import SpanEtat from '../components/etat_components/SpanEtat';
import ArrowBottom from '../svg/ArrowBottom';
import DossierBanqueModal from '../components/DossierBanqueModal';
import DossierGarantieModal from '../components/DossierGarantieModal';
import TheadFilter from '../components/th_filter_component/TheadFilter';
import ConsultPage from '../components/ConsultPage';
import DownloadPage from '../components/DownloadPage';
import moment from 'moment'
import VersBanqueModal from '../components/VersBanqueModal';
import EchiancierPage from '../components/EchiancierPage';
import XLSX from 'sheetjs-style'    /*  For Excel Download  */
import * as FileSaver from 'file-saver'  /*  For Excel Download  */

function HomePage() {

    const navigate = useNavigate();
    const { page = 1 } = useParams();

    const { user, setUser, itemActive, setItemActive, filterParams, setFilterParams } = useContext(UserContext);

    const [id, setId] = useState(filterParams.id || "");
    const [nom, setNom] = useState(filterParams.nom || "");
    const [dossierBanque, setDossierBanque] = useState(filterParams.dossierBanque || "");
    const [dossierClient, setDossierClient] = useState(filterParams.dossierClient || "");
    const [etat, setEtat] = useState(filterParams.etat || "");
    const [ccr, setCcr] = useState(filterParams.ccr || "");
    const [searchBox, setSearchBox] = useState(filterParams.searchBox || "");
    // const [vehicule, setVehicule] = useState(filterParams.vehicule || "");
    const [actionBanque, setActionBanque] = useState(filterParams.actionBanque || "");
    const [pages, setPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [dateInsertion, setDateInsertion] = useState("");
    const [demandes, setDemandes] = useState(null);
    const [tableShow, setTableShow] = useState(false);
    const [hash, setHash] = useState("");
    const [nbrAll, setNbrAll] = useState('');
    const [nbrConsult, setNbrConsult] = useState('');
    const [nbrNotify, setNbrNotify] = useState('');
    const [nbrConfirmDelivery, setNbrConfirmDelivery] = useState('');
    const [nbrConfirmPayment, setNbrConfirmPayment] = useState('');
    const [nbrArchive, setNbrArchive] = useState('');
    const [nbrReception, setNbrReception] = useState('');
    const [nbrControl, setNbrControl] = useState('');
    const [nbrFollow, setNbrFollow] = useState('');
    const [nbrComplement, setNbrComplement] = useState('');
    const [nbrComplementFacture, setNbrComplementFacture] = useState('');
    const [nbrReceptionGarantieBanque, setNbrReceptionGarantieBanque] = useState('');
    const [nbrNB, setNbrNB] = useState('');
    const [nbrLivraison, setNbrLivraison] = useState('');
    const [nbrAnnulee, setNbrAnnulee] = useState('');

    /** To refresh data when i change actionBanque **/
    const [filter, setFilter] = useState(false);

    /** To hide/show filter section **/
    const [filterPopup, setFilterPopup] = useState(true);

    /** To refresh data when validation dossier value change **/
    /** To refresh data when Vers Banque value change **/
    const [check, setCheck] = useState(false)

    /** To hide/show thead filter **/
    const [mtProduitActive, setMtProduitActive] = useState(false);
    const [typeActive, setTypeActive] = useState(false);
    const [vehiculeActive, setVehiculeActive] = useState(false);
    const [dateActionBanque, setDateActionBanqueActive] = useState(false);
    const [dateAccuse, setDateAccuseActive] = useState(false);

    /** To add field and orderBy to getCommands post **/
    const [fieldAttribute, setFieldAttribute] = useState('');
    const [orderByAttribute, setOrderByAttribute] = useState('');

    /** To make demand number group consultation storage **/
    const [consultGroupList, setConsultGroupList] = useState([]);

    /** To make ConsulPage and DownloadPage component shown/hidden **/
    const [consultPageActive, setConsultPageActive] = useState(false);
    const [downloadPageActive, setDownloadPageActive] = useState(false);
    const [echiancierPageActive, setEchiancierPageActive] = useState(false);

    /************ Begin useEffect ************/
    useEffect(() => {
        getCommands();
    }, [page, actionBanque, check])

    useEffect(() => {
        if (parseInt(page) <= parseInt(pages) - 3) {
            setPageNumber(parseInt(page));
        } else {
            setPageNumber(parseInt(pages) - 3)
        }
    }, [page, pages])

    useEffect(() => {
        setNom('');
        setDossierBanque('');
        setDossierClient('');
        setEtat('');
        setCcr('');
    }, [id])

    useEffect(() => {
        setId('');
    }, [nom, dossierBanque, dossierClient, etat, ccr])

    // useEffect used for id or name or dossierBanque or dossierClient or etat change
    useEffect(() => {
        getCommands();
    }, [searchBox])


    // useEffect(() => {
    //     getCommands();
    // }, [vehicule])

    useEffect(() => {
        getCommands();
    }, [filter])

    // useEffect to attach active Action Banque
    useEffect(() => {
        setItemActive('acceuil')
        localStorage.setItem('itemActive', 'acceuil')

        /*
        document.querySelector(".home-page .taches-section ul li.tache-active").classList.remove('tache-active');
        if(user.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN')){
            switch (actionBanque) {
                case "1":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(2)").classList.add("tache-active");
                case "2":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(3)").classList.add("tache-active");
                case "3":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(4)").classList.add("tache-active");
                case "12":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(5)").classList.add("tache-active");
                case "4":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(6)").classList.add("tache-active");
                case "5":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(7)").classList.add("tache-active");
                case "6":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(8)").classList.add("tache-active");    
                case "13":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(9)").classList.add("tache-active");
                case "11":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(10)").classList.add("tache-active");
                case "7":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(11)").classList.add("tache-active");
                case "9":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(12)").classList.add("tache-active");
                case "8":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(13)").classList.add("tache-active");
                case "10":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(14)").classList.add("tache-active");
                default:
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(1)").classList.add("tache-active");
            }
        }else{
            switch (actionBanque) {
                case "1":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(2)").classList.add("tache-active");
                // case "2":
                //     return document.querySelector(".home-page .taches-section ul li:nth-of-type(3)").classList.add("tache-active");
                case "3":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(4)").classList.add("tache-active");
                case "12":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(5)").classList.add("tache-active");
                // case "4":
                //     return document.querySelector(".home-page .taches-section ul li:nth-of-type(6)").classList.add("tache-active");
                case "5":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(7)").classList.add("tache-active");
                case "6":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(8)").classList.add("tache-active");    
                case "13":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(9)").classList.add("tache-active");
                // case "11":
                //     return document.querySelector(".home-page .taches-section ul li:nth-of-type(10)").classList.add("tache-active");
                case "7":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(11)").classList.add("tache-active");
                case "9":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(12)").classList.add("tache-active");
                case "8":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(13)").classList.add("tache-active");
                case "10":
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(14)").classList.add("tache-active");
                default:
                    return document.querySelector(".home-page .taches-section ul li:nth-of-type(1)").classList.add("tache-active");
            }
        }*/
    }, [])

    // useEffect of add field and orderBy to getCommands
    useEffect(() => {
        //console.log(fieldAttribute, orderByAttribute);
        getCommands();
        theadActiveCaretClick();
    }, [fieldAttribute, orderByAttribute])

    /****** Begin Methods ******/
    function getDate(dd) {
        var s = new Date(dd.timestamp * 1000).toLocaleDateString("fr-FR")
        return s;
    }

    const getCounter = (dab, dtb) => {

        /*** First Method ***/
        //    let startTime = moment('06:45:20', 'h:mm:ss').format("HH:mm:ss");
        //     var startDate = new Date(`5/19/2023 ${startTime}`);

        //     let currentTime = moment(new Date(), 'h:mm:ss').format("HH:mm:ss")
        //     var currentDay = moment(new Date()).format("MM-DD-YYYY"); 
        //     var currentDate = new Date(`${currentDay} ${currentTime}`);
        //     var diffTime = moment(currentDate).diff(startDate);

        //     var duration = moment.duration(diffTime);
        //     var years = duration.years(),
        //      days = duration.days(),
        //      months = duration.months(),
        //      hrs = duration.hours(),
        //      mins = duration.minutes(),
        //      secs = duration.seconds();

        //      console.log(years + ' years ' + months + 'months ' + days + ' days ' + hrs + ' hrs ' + mins + ' mins ' + secs + ' sec');

        /*** Second Method ***/
        // var currentDate = moment(new Date()).format('MM-DD-YYYY HH:mm:ss');
        // var startDate = moment(`${dab} ${dtb}`, 'DD/MM/YYYY h:mm:ss').format('MM-DD-YYYY HH:mm:ss');

        // var cd = new Date(currentDate);
        // var sd = new Date(startDate);
        // var diffTime = moment(cd).diff(sd);
        // var duration = moment.duration(diffTime);
        // var years = duration.years(),
        //  days = duration.days(),
        //  months = duration.months(),
        //  hrs = duration.hours(),
        //  mins = duration.minutes(),
        //  secs = duration.seconds();
        //console.log(years + ' years ' + months + 'months ' + days + ' days ' + hrs + ' hrs ' + mins + ' mins ' + secs + ' sec');
        //  return `${days} ${hrs}:${mins}:${secs}`;

        var currentDate = moment(new Date()).format('MM-DD-YYYY HH:mm:ss');
        var startDate = moment(`${dab} ${dtb}`, 'DD/MM/YYYY h:mm:ss').format('MM-DD-YYYY HH:mm:ss');
        var cd = new Date(currentDate);
        var sd = new Date(startDate);
        var hours = moment(cd).diff(sd, 'hours');
        var result = hours - weekend_calculator(dab);
        return `${result} H`
    }

    var weekend_calculator = (startDate) => {
        var endDate = moment().format('DD/MM/YYYY');
        var i = 0;
        var minus_hours = 0;
        do {
            var new_date = moment(moment(startDate, 'DD/MM/YYYY').add(i, 'd').format('DD/MM/YYYY'), 'DD/MM/YYYY');
            var weekday = new_date.locale('en').format('dddd');
            i = i + 1;
            if (weekday === 'Friday' || weekday === 'Saturday') minus_hours = minus_hours + 24;
        } while (endDate !== new_date.format('DD/MM/YYYY'))

        return minus_hours;
    }

    let data_formulate = (startDate) => {
        var year = startDate.substring(0, 2);
        var month = startDate.substring(2, 4);
        var day = startDate.substring(4, 6);

        return ('20' + year + '/' + month + '/' + day);
    }

    const logoutFunction = () => {
        setUser(null)
        setItemActive('')
        setFilterParams('')
        localStorage.removeItem('userInfo');
        localStorage.removeItem('itemActive');
        localStorage.removeItem('filterParams');
    }

    function checkSize(value) {
        if (value.length > 8)
            return value.substring(0, 4) + '...';
        else
            return value;
    }

    function onSearch() {
        // if(actionBanque == ""){
        //     getCommands();
        // }
        // setActionBanque('');
        // getCommands();
        // if(page != 1){
        //     navigate(`/banque/page/${1}`);
        // }
        navigate(`/banque/page/${1}`);
        setSearchBox(t => !t);

        // document.querySelector(".home-page .taches-section ul li.tache-active").classList.remove('tache-active');
        // document.querySelector(".home-page .taches-section ul li:first-child").classList.add('tache-active');

        // setFilterParams({ ...filterParams, actionBanque: "" });
        // localStorage.setItem('filterParams', JSON.stringify({ ...filterParams, actionBanque: "" }));
    }

    function tache_active(ab, event) {
        setId('');
        setNom('');
        setDossierBanque('');
        setDossierClient('');
        setEtat('');
        if (ab === '') {
            setFilter(fl => !fl);
        }
        // document.querySelector(".home-page .taches-section ul li.tache-active").classList.remove('tache-active');
        // event.currentTarget.classList.add("tache-active");
        setActionBanque(ab);
        navigate(`/banque/page/${1}`);


        setFilterParams({ ...filterParams, actionBanque: ab });
        localStorage.setItem('filterParams', JSON.stringify({ ...filterParams, actionBanque: ab }));
    }

    function onclickNext() {
        if (page < pages) {
            navigate(`/banque/page/${parseInt(page) + 1}`)
        }
    }

    function onclickPrevious() {
        if (page > 1) {
            navigate(`/banque/page/${parseInt(page) - 1}`)
        }
    }

    function goToDetailHandler(demandeHash) {
        navigate(`/banque/detail/${demandeHash}`);
        // let filterParamsObject = { id: id, nom: nom, dossierBanque: dossierBanque, dossierClient: dossierClient, etat: etat, actionBanque: actionBanque };
        // setFilterParams(filterParamsObject);
        // localStorage.setItem('filterParams', JSON.stringify(filterParamsObject));
    }

    const getCommands = async () => {
        var cp = page || 1;
        setTableShow(false);
        var current = new Date();
        var day = current.getDate();
        var month = current.getMonth() + 1;
        var year = current.getFullYear();
        var df = year.toString().substring(2) + '' + month + '' + day;

        var model = {};
        // if (actionBanque !== '') {
        //     model = { username: user.user, orderId: user.orderId, dd: '191015', df: df, actionBanque: actionBanque }
        // } else {
        //     model = {
        //         id, date_insertion: "", nom, dossierBanque: dossierBanque, dossier2: dossierClient, etat, username: user.user,
        //         orderId: user.orderId, dd: '191015', df: df, actionBanque: '', vehicule
        //     }
        // }

        model = {
            id, date_insertion: "", nom, dossierBanque: dossierBanque, dossier2: dossierClient, etat, ccr, username: user.user,
            orderId: user.orderId, dd: '191015', df: df, actionBanque: actionBanque, orderDirection: orderByAttribute, orderBy: fieldAttribute
        }

        await axios
            .post(`https://diardzair.com.dz/api/taksit/rest/get/actionBanque`,
                JSON.stringify({ username: user.user, orderId: user.orderId, dd: '191015', df: df }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                })
            .then((res) => {
                if (res.data.errorCode === 7) {
                    logoutFunction();
                }
                setNbrAll(res.data['p0']);
                setNbrConsult(res.data['p1']);
                setNbrComplement(res.data['p2']);
                setNbrReception(res.data['p3']);
                setNbrNotify(res.data['p4']);
                setNbrComplementFacture(res.data['p5']);
                setNbrConfirmPayment(res.data['p6']);
                setNbrConfirmDelivery(res.data['p7']);
                setNbrControl(res.data['p8']);
                setNbrReceptionGarantieBanque(res.data['p9']);
                setNbrFollow(res.data['p10']);
                setNbrArchive(res.data['p11']);
                setNbrNB(res.data['p12']);
                setNbrLivraison(res.data['p13'] || 0);
                setNbrAnnulee(res.data['p14'] || 0);
            })
            .catch((err) => {
                console.log(err);
            });
        await axios
            .post(`https://diardzair.com.dz/api/taksit/rest/get/formData?pageSize=${pageSize}&page=${cp}`, JSON.stringify(model),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                })
            .then((res) => {
                setDemandes(res.data["0"]);
                setPages(res.data.pageTotal);
                setTableShow(true);
                // console.log(res.data["0"]);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const downloadAll = async () => {
        var current = new Date();
        var day = current.getDate();
        var month = current.getMonth() + 1;
        var year = current.getFullYear();
        var df = year.toString().substring(2) + '' + month + '' + day;

        var model = {};
        if (actionBanque !== '') {
            model = { username: user.user, orderId: user.orderId, dd: '191015', df: df, actionBanque: actionBanque }
        } else {
            model = {
                id, date_insertion: "", nom, dossierBanque: dossierBanque, dossier2: dossierClient, etat, username: user.user,
                orderId: user.orderId, dd: '191015', df: df, actionBanque: ''
            }
        }
        await axios
            .post(`https://diardzair.com.dz/api/taksit/rest/get/formDataFile`, JSON.stringify(model),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                },
                { responseType: 'blob' },
            )
            .then((res) => {
                fileDownload(res.data, 'liste_de_commandes.csv');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const theadActiveCaretClick = (type) => {
        if (type === 'dateActionBanqueField') {
            setDateActionBanqueActive(true);
            setMtProduitActive(false);
            setVehiculeActive(false);
            setTypeActive(false);
            setDateAccuseActive(false);
        } else if (type === 'MTProduitField') {
            setDateActionBanqueActive(false);
            setMtProduitActive(true);
            setVehiculeActive(false);
            setTypeActive(false);
            setDateAccuseActive(false);
        } else if (type === 'VehiculeField') {
            setDateActionBanqueActive(false);
            setMtProduitActive(false);
            setVehiculeActive(true);
            setTypeActive(false);
            setDateAccuseActive(false);
        } else if (type === 'TypeField') {
            setDateActionBanqueActive(false);
            setMtProduitActive(false);
            setVehiculeActive(false);
            setTypeActive(true);
            setDateAccuseActive(false);
        } else if (type === 'dateAccuseField') {
            setDateActionBanqueActive(false);
            setMtProduitActive(false);
            setVehiculeActive(false);
            setTypeActive(false);
            setDateAccuseActive(true);
        } else {
            setDateActionBanqueActive(false);
            setMtProduitActive(false);
            setVehiculeActive(false);
            setTypeActive(false);
            setDateAccuseActive(false);
        }
    }

    const addConsultGroup = (num_demande) => {
        var idx = consultGroupList.indexOf(num_demande);
        if (idx > -1) {
            setConsultGroupList(t => t.filter(item => item !== num_demande));
        } else {
            setConsultGroupList(t => [...t, num_demande]);
        }
    }

    const refreshHandle = () => {
        setOrderByAttribute('');
        setFieldAttribute('');

        setDateActionBanqueActive(false);
        setMtProduitActive(false);
        setVehiculeActive(false);
        setTypeActive(false);
        setDateAccuseActive(false);

        setConsultGroupList(t => []);
    }

    const downloadPDFs = () => {
        consultGroupList.map(async (numero_demande) => {

            // try {
            //     const response = await axios.post(
            //         `https://diardzair.tech/api/generate-pdf-alsalam/${numero_demande}}`,
            //       {
            //         responseType: "blob", 
            //       }
            //     );

            //     // Create a Blob from the response data
            //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });

            //     // Create a temporary URL for the Blob
            //     const url = window.URL.createObjectURL(pdfBlob);

            //     // Create a temporary <a> element to trigger the download
            //     const tempLink = document.createElement("a");
            //     tempLink.href = url;
            //     tempLink.setAttribute(
            //       "download",
            //       `bill_${numero_demande}.pdf`
            //     ); // Set the desired filename for the downloaded file

            //     // Append the <a> element to the body and click it to trigger the download
            //     document.body.appendChild(tempLink);
            //     tempLink.click();

            //     // Clean up the temporary elements and URL
            //     document.body.removeChild(tempLink);
            //     window.URL.revokeObjectURL(url);
            //   } catch (error) {
            //     console.error("Error downloading PDF:", error);
            //   }

            axios.get(`https://diardzair.tech/api/generate-pdf-alsalam/${numero_demande}}`,
                // axios.get(`http://127.0.0.1:8000/api/generate-pdf-alsalam/${numero_demande}}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf',
                        'Authorization': 'diardckczair'
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `pdf_alsalam_${numero_demande}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => console.log(error));

        });
        refreshHandle();
    }

    const exportToExcel = async(filename) => {
        // npm install file-saver
        // npm install sheetjs-style
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx'
        const excelData = []

        if (demandes.length !== 0){
            demandes.map(demande =>{
                excelData.push({
                    "nom" : demande.nom,
                    "prenom" : demande.prenom,
                    "date_naissance" : demande.dateNaissance,
                    "nin" : demande.nin,
                    "num_act_naissance" : demande.numActNaissance
                })
            })
        } 

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets : {'data' : ws} , SheetNames : ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
    }



    return (

        <div className="home-page">
            <div className="row">
                <div className={filterPopup ? `filter-section active` : 'filter-section'}>
                    <h2>Filtrage</h2>
                    <span className='popup' onClick={() => setFilterPopup(!filterPopup)}>
                        <ArrowBottom />
                    </span>
                    <div className="search-by-id-section">
                        <div className="row">
                            <div className="col-lg-6 col-md-9 col-sm-12">
                                <div className="form">
                                    <div className="row">
                                        <div className="form-group col-md-4 col-12">
                                            <span className="sub-title">Recherche par ID</span>
                                        </div>
                                        <div className="form-group col-md-4 col-12">
                                            <label>Identifiant</label>
                                            <input className="form-control" placeholder="Identifiant" type="number"
                                                name='id' value={id} onChange={e => setId(e.target.value)} />
                                        </div>
                                        <div className="form-group col-md-4 col-12 position-relative">
                                            <span className="btn absolute-bottom-right"
                                                onClick={() => onSearch()}>Recherche</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-advanced-section">
                        <div className="row">
                            <div className="col-md-2 col-sm-12">
                                <span className="sub-title">Recherche Avancée</span>
                            </div>
                            <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Nom</label>
                                        <input type="text" className="form-control" placeholder="Nom" id='nom' value={nom}
                                            onChange={e => setNom(e.target.value)} />
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Dossier Banque</label>
                                        <select className="form-select" value={dossierBanque} onChange={e => setDossierBanque(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="1">True</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Dossier Garantie</label>
                                        <select className="form-select" value={dossierClient} onChange={e => setDossierClient(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="1">True</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Etat</label>
                                        {/* <select className="form-select" value={etat} onChange={e => setEtat(e.target.value)} >
                                            <option value="">Tout</option>
                                            <option value="0">Non Traité</option>
                                            <option value="1">Accepter</option>
                                            <option value="2">Refuser</option>
                                            <option value="3">Approuver</option>
                                            <option value="4">Annuler</option>
                                            <option value="5">Finançable</option>
                                            <option value="6">Non Finançable</option>
                                            <option value="7">Rejeter</option>
                                            <option value="8">Contrôler</option>
                                        </select> */}
                                        <SpanEtat etat={etat} type="selectHome" etatFunction={setEtat} />
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Type d'Achat</label>
                                        <select className="form-select" value={ccr} onChange={e => setCcr(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="1">ASE</option>
                                            <option value="0">AUE</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12 position-relative">
                                        <span className="btn absolute-bottom-right" onClick={() => onSearch()}>Recherche</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/*   Begin Tâches  */}
                <div className="col-xl-3 col-lg-12 padding-right margin-bottom">
                    <div className="taches-section">
                        <h2>Tâches</h2>
                        <ul>
                            <li /*className="tache-active"*/ onClick={e => tache_active('', e)} className={actionBanque == '' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheTout color={"no-color"} />
                                    <span>Tous les dossiers ({nbrAll})</span>
                                </div>
                                <div className="with-color">
                                    <TacheTout color={"with-color"} />
                                    <span>Tous les dossiers ({nbrAll})</span>
                                </div>
                            </li>
                            <b>Traitement</b>
                            <li onClick={e => tache_active('1', e)} className={actionBanque == '1' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheConsultationCentralRisque color={"no-color"} />
                                    <span>Consultation Central Risque ({nbrConsult})</span>
                                </div>
                                <div className="with-color">
                                    <TacheConsultationCentralRisque color={"with-color"} />
                                    <span>Consultation Central risque ({nbrConsult})</span>
                                </div>
                            </li>
                            {
                                user.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN') && (
                                    <li onClick={e => tache_active('2', e)} className={actionBanque == '2' ? 'tache-active' : ''}>
                                        <div className="no-color">
                                            <TacheComplementInformationBanque color={"no-color"} />
                                            <span>Complément d'Information Banque ({nbrComplement})</span>
                                        </div>

                                        <div className="with-color">
                                            <TacheComplementInformationBanque color={"with-color"} />
                                            <span>Complément d'Information Banque ({nbrComplement})</span>
                                        </div>
                                    </li>
                                )
                            }
                            <li onClick={e => tache_active('3', e)} className={actionBanque == '3' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheReceptionDossierBanque color={"no-color"} />
                                    <span>Pré-Notification Banque ({nbrReception})</span>
                                </div>

                                <div className="with-color">
                                    <TacheReceptionDossierBanque color={"with-color"} />
                                    <span>Pré-Notification Banque ({nbrReception})</span>
                                </div>
                            </li>

                            <li onClick={e => tache_active('12', e)} className={actionBanque == '12' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheNotificationBanque color={"no-color"} />
                                    <span>Notification Banque ({nbrNB})</span>
                                </div>
                                <div className="with-color">
                                    <TacheNotificationBanque color={"with-color"} />
                                    <span>Notification Banque ({nbrNB})</span>
                                </div>
                            </li>

                            {
                                user.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN') && (
                                    <li onClick={e => tache_active('4', e)} className={actionBanque == '4' ? 'tache-active' : ''}>
                                        <div className="no-color">
                                            <TacheNotificationBanque color={"no-color"} />
                                            <span>Notification Banque Diar Dzair ({nbrNotify})</span>
                                        </div>
                                        <div className="with-color">
                                            <TacheNotificationBanque color={"with-color"} />
                                            <span>Notification Banque Diar Dzair ({nbrNotify})</span>
                                        </div>
                                    </li>
                                )
                            }

                            <li onClick={e => tache_active('5', e)} className={actionBanque == '5' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheControleDossierPhysiqueBanque color={"no-color"} />
                                    <span>Confirmation Paiement Banque ({nbrComplementFacture})</span>
                                </div>
                                <div className="with-color">
                                    <TacheControleDossierPhysiqueBanque color={"with-color"} />
                                    <span>Confirmation Paiement Banque ({nbrComplementFacture})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('6', e)} className={actionBanque == '6' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheConfirmationPaiementBanque color={"no-color"} />
                                    <span>Mobilisation Stock ASBA ({nbrConfirmPayment})</span>
                                </div>

                                <div className="with-color">
                                    <TacheConfirmationPaiementBanque color={"with-color"} />
                                    <span>Mobilisation Stock ASBA ({nbrConfirmPayment})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('13', e)} className={actionBanque == '13' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheSuiviDossier color={"no-color"} />
                                    <span>Livraison ({nbrLivraison})</span>
                                </div>

                                <div className="with-color">
                                    <TacheSuiviDossier color={"with-color"} />
                                    <span>Livraison ({nbrLivraison})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('14', e)} className={actionBanque == '14' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheSuiviDossier color={"no-color"} />
                                    <span>Annulée ({nbrAnnulee})</span>
                                </div>

                                <div className="with-color">
                                    <TacheSuiviDossier color={"with-color"} />
                                    <span>Annulée ({nbrAnnulee})</span>
                                </div>
                            </li>


                            {
                                user.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN') && (
                                    <li onClick={e => tache_active('11', e)} className={actionBanque == '11' ? 'tache-active' : ''}>
                                        <div className="no-color">
                                            <TacheSuiviDossier color={"no-color"} />
                                            <span>Archive ({nbrArchive})</span>
                                        </div>

                                        <div className="with-color">
                                            <TacheSuiviDossier color={"with-color"} />
                                            <span>Archive ({nbrArchive})</span>
                                        </div>
                                    </li>
                                )
                            }
                            {/* <li onClick={e => tache_active('15', e)} className={actionBanque == '15' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheControleDossierPhysiqueBanque color={"no-color"} />
                                    <span>Echianciers ({nbrConfirmDelivery})</span>
                                </div>

                                <div className="with-color">
                                    <TacheControleDossierPhysiqueBanque color={"with-color"} />
                                    <span>Echianciers  ({nbrConfirmDelivery})</span>
                                </div>
                            </li> */}

                            <b>Garanties</b>
                            <li onClick={e => tache_active('7', e)} className={actionBanque == '7' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheControleDossierPhysiqueBanque color={"no-color"} />
                                    <span>Controle Dossier Garantie Diar Dzair ({nbrConfirmDelivery})</span>
                                </div>

                                <div className="with-color">
                                    <TacheControleDossierPhysiqueBanque color={"with-color"} />
                                    <span>Controle Dossier Garantie Diar Dzair  ({nbrConfirmDelivery})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('9', e)} className={actionBanque == '9' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheReceptionDossierBanque color={"no-color"} />
                                    <span>Réception  Garantie Banque ({nbrReceptionGarantieBanque})</span>
                                </div>

                                <div className="with-color">
                                    <TacheReceptionDossierBanque color={"with-color"} />
                                    <span>Réception  Garantie Banque ({nbrReceptionGarantieBanque})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('8', e)} className={actionBanque == '8' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheControleDossierPhysiqueBanque color={"no-color"} />
                                    <span>Complément Garantie ({nbrControl})</span>
                                </div>

                                <div className="with-color">
                                    <TacheControleDossierPhysiqueBanque color={"with-color"} />
                                    <span>Complément Garantie ({nbrControl})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('10', e)} className={actionBanque == '10' ? 'tache-active' : ''}>
                                <div className="no-color">
                                    <TacheSuiviDossier color={"no-color"} />
                                    <span>Suivi Dossier Au Niveau Banque ({nbrFollow})</span>
                                </div>

                                <div className="with-color">
                                    <TacheSuiviDossier color={"with-color"} />
                                    <span>Suivi Dossier Au Niveau Banque ({nbrFollow})</span>
                                </div>
                            </li>


                            {/* <li onClick={e => tache_active('9', e)}>
                                <div className="no-color">
                                    <TacheRejete color={"no-color"} />
                                    <span>Rejeté ({nbrReject})</span>
                                </div>

                                <div className="with-color">
                                    <TacheRejete color={"with-color"} />
                                    <span>Rejeté ({nbrReject})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('10', e)}>
                                <div className="no-color">
                                    <TacheCancel color={"no-color"} />
                                    <span>Annulé ({nbrAnnuler})</span>
                                </div>

                                <div className="with-color">
                                    <TacheCancel color={"with-color"} />
                                    <span>Annulé ({nbrAnnuler})</span>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>

                {/*   Begin Table  */}
                <div className="col-xl-9 col-lg-12 padding-left margin-bottom">
                    <div className="commands-section">
                        <h2>Tableau des Commandes</h2>
                        {/* <div className='vehicule-checkbox'>
                            <span>Vehicule</span>
                            <div className="box">
                                <input type="checkbox" id="check" checked={vehicule === "1" ? true :false} onChange={onVehiculeChange}/>
                                <label htmlFor="check"></label>
                            </div>
                        </div> */}
                        <div className='download-table'>
                            {
                                actionBanque !== '15' ? (
                                    <>
                                        <span onClick={() => setConsultPageActive(true)} style={{ display: consultGroupList.length === 0 ? 'none' : '' }}>Consultation Groupée</span>
                                        <span onClick={() => setDownloadPageActive(true)} style={{ display: consultGroupList.length === 0 ? 'none' : '' }}>Téléchargement Groupée</span>
                                        <span onClick={() => downloadPDFs()} style={{ display: consultGroupList.length === 0 ? 'none' : '' }}>PDF Groupée</span>
                                        {actionBanque === '1' && <span onClick={()=> exportToExcel('central_risque')}>Télécharger Excel</span> } 
                                        <span onClick={downloadAll} className='right-side'>Télécharger</span>
                                        <span onClick={refreshHandle} className='right-side'>Rafraîchir</span>
                                        <ConsultPage consultPageActive={consultPageActive} setConsultPageActive={setConsultPageActive} consultGroupList={consultGroupList} getCommands={getCommands} refreshHandle={refreshHandle} />
                                        <DownloadPage downloadPageActive={downloadPageActive} setDownloadPageActive={setDownloadPageActive} consultGroupList={consultGroupList} refreshHandle={refreshHandle} />
                                    </>
                                ) : (
                                    <>
                                        <span onClick={() => setEchiancierPageActive(true)} style={{ display: echiancierPageActive === true ? 'none' : '' }} className='right-side'>Upload</span>
                                        <EchiancierPage echiancierPageActive={echiancierPageActive} setEchiancierPageActive={setEchiancierPageActive} getCommands={getCommands} refreshHandle={refreshHandle} />
                                    </>
                                )
                            }</div>
                        {/* Begin Table  */}
                        {
                            tableShow ?
                                (demandes?.length !== 0) ?
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {
                                                    actionBanque !== '15' ? (
                                                        <>
                                                            <th></th>
                                                            <th className='th-filter'>
                                                                DAB
                                                                <span>
                                                                    Date Action Banque <br />
                                                                    Format DD/MM/YY
                                                                </span>
                                                                <i onClick={() => theadActiveCaretClick('dateActionBanqueField')} className="fa fa-caret-down" aria-hidden="true"></i>
                                                                <TheadFilter type={'dateActionBanqueField'} fieldActive={dateActionBanque} setFieldAttribute={setFieldAttribute} setOrderByAttribute={setOrderByAttribute} />
                                                            </th>
                                                            <th>ID</th>
                                                            <th className='th-filter'>
                                                                TC
                                                                <span>
                                                                    Type d'Achat
                                                                </span>
                                                            </th>
                                                            <th>Nom.Prenom</th>
                                                            <th className='th-filter'>
                                                                MTP
                                                                <span> Montant Produit</span>
                                                                <i onClick={() => theadActiveCaretClick('MTProduitField')} className="fa fa-caret-down" aria-hidden="true"></i>
                                                                <TheadFilter type={'MTProduitField'} fieldActive={mtProduitActive} setFieldAttribute={setFieldAttribute} setOrderByAttribute={setOrderByAttribute} />
                                                            </th>
                                                            <th className='th-filter'>
                                                                Vehicule
                                                                <i onClick={() => theadActiveCaretClick('VehiculeField')} className="fa fa-caret-down" aria-hidden="true"></i>
                                                                <TheadFilter type={'VehiculeField'} fieldActive={vehiculeActive} setFieldAttribute={setFieldAttribute} setOrderByAttribute={setOrderByAttribute} />
                                                            </th>
                                                            <th className='th-filter'>
                                                                Type
                                                                <i onClick={() => theadActiveCaretClick('TypeField')} className="fa fa-caret-down" aria-hidden="true"></i>
                                                                <TheadFilter type={'TypeField'} fieldActive={typeActive} setFieldAttribute={setFieldAttribute} setOrderByAttribute={setOrderByAttribute} />
                                                            </th>
                                                            <th className='th-filter'>
                                                                DA
                                                                <span>
                                                                    Date Accusé<br />
                                                                    Format DD/MM/YY
                                                                </span>
                                                                <i onClick={() => theadActiveCaretClick('dateAccuseField')} className="fa fa-caret-down" aria-hidden="true"></i>
                                                                <TheadFilter type={'dateAccuseField'} fieldActive={dateAccuse} setFieldAttribute={setFieldAttribute} setOrderByAttribute={setOrderByAttribute} />
                                                            </th>
                                                            <th>Conteur</th>
                                                            <th>
                                                                VB
                                                                <span>Vers Banque</span>
                                                            </th>
                                                            <th>Valider Dossier</th>
                                                            <th>Etat</th>
                                                            <th></th>
                                                        </>
                                                    ) : (
                                                        <>
                                                          <th></th>
                                                          <th>ID</th>
                                                          <th>Nom Document</th>
                                                          <th>Extension</th>
                                                          <th>propriétaire</th>
                                                          <th>Date de Upload</th>
                                                          <th></th>
                                                        </>
                                                    )
                                                }
                                                
                                            </tr>
                                        </thead>
                                        <tbody onClick={theadActiveCaretClick}>
                                            {
                                                demandes?.map((demande, index) => (
                                                        actionBanque !== 15 ? (
                                                            <tr key={index} style={{ background: demande.action === "vérifier_par_e-financement_couloir_vert" ? "#C3EDC0" : demande.action === "vérifier_par_e-finance_dossier_physique" ? "rgba(241,201,60,.5)" : "" }}>
                                                                <td>
                                                                    <div className={consultGroupList.indexOf(demande.numero_demande) > -1 ? 'mini-rect-div active' : 'mini-rect-div'} onClick={() => addConsultGroup(demande.numero_demande)}></div>
                                                                </td>
                                                                <td>{demande.actionBanqueDate ? moment(getDate(demande.actionBanqueDate), 'DD/MM/YYYY').format('DD/MM/YY') : '---'}</td>
                                                                <td>{demande.numero_demande}</td>
                                                                <td>{demande.ccr === true ? "ASE" : demande.vehicule ? "" : "AUE"}</td>
                                                                <td>{checkSize(demande.nom) + '.' + checkSize(demande.prenom)}</td>
                                                                <td>{demande.mt_produit}</td>
                                                                <td>{demande.vehicule ? 'vehicule' : 'electro'}</td>
                                                                <td>{'Standard'}</td>
                                                                <td>{demande.dossier_banque_date ? moment(demande.dossier_banque_date, 'DD/MM/YYYY').format('DD/MM/YY') : '---'}</td>
                                                                <td>{(demande.actionBanqueDate && demande.actionBanqueTime) ? getCounter(getDate(demande.actionBanqueDate), demande.actionBanqueTime) : '---'}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        demande.actionBanque === "1" ? '---'
                                                                            : demande.actionBanque === "2" ?
                                                                                <div style={{ cursor: 'pointer' }} onClick={() => setHash(demande.hash)} data-bs-toggle="modal"
                                                                                    data-bs-target="#versBanqueModal" id="rect-div">
                                                                                </div>
                                                                                : <i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        demande.dossier_banque ? <i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i>
                                                                            : <div style={{ cursor: 'pointer' }} onClick={() => setHash(demande.hash)} data-bs-toggle="modal"
                                                                                data-bs-target="#dossierBanqueModal" id="rect-div">
                                                                            </div>
                                                                    }
                                                                    <span style={{ margin: "0 10px" }}></span>
                                                                    {
                                                                        demande.dossier_garantie ? <i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i>
                                                                            : <div style={{ cursor: 'pointer' }} onClick={() => setHash(demande.hash)} data-bs-toggle="modal"
                                                                                data-bs-target="#dossierGarantieModal" id="rect-div">
                                                                            </div>
                                                                    }
                                                                </td>
                                                                {
                                                                    <td className='etat'>
                                                                        <SpanEtat etat={demande.etat} />
                                                                    </td>
                                                                }
                                                                <td onClick={() => goToDetailHandler(demande.hash)}>
                                                                    <Search />
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className={consultGroupList.indexOf(demande.id) > -1 ? 'mini-rect-div active' : 'mini-rect-div'} onClick={() => addConsultGroup(demande.id)}></div>
                                                                </td>
                                                                <td>ID</td>
                                                                <td>Nom Document</td>
                                                                <td>Extension</td>
                                                                <td>propriétaire</td>
                                                                <td>Date de Upload</td>
                                                                <td>
                                                                    <Search />
                                                                </td>
                                                            </tr>
                                                        )
                                                    
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    : <div className='no-data-wrapper'>Pas de Données Disponibles</div>
                                : (
                                    <LoadingComponent />
                                )
                        }
                        {/* End Table */}

                        {/* Begin Pagination */}
                        {
                            (tableShow && pages && pages > 1) ? (
                                <div className="pagination">
                                    {
                                        parseInt(pages) <= 8 ? (
                                            <div className="pagination-content">
                                                <span onClick={onclickPrevious}>
                                                    <i className="fa-solid fa-angle-left"></i>
                                                </span>
                                                <span onClick={() => navigate(`/banque/page/${1}`)} > Premier </span>
                                                {
                                                    [...Array(pages).keys()].map(x => (
                                                        <span key={x + 1} className={x + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/page/${x + 1}`)}>
                                                            {x + 1}
                                                        </span>
                                                    ))
                                                }
                                                <span onClick={() => navigate(`/banque/page/${pages}`)} > Dernier </span>
                                                <span onClick={onclickNext} >
                                                    <i className="fa-solid fa-angle-right"></i>
                                                </span>
                                            </div>
                                        ) :
                                            (
                                                <div className="pagination-content">
                                                    <span onClick={onclickPrevious}>
                                                        <i className="fa-solid fa-angle-left"></i>
                                                    </span>
                                                    <span onClick={() => navigate(`/banque/page/${1}`)} > Premier </span>
                                                    {
                                                        (pageNumber - 1 > 0) && (
                                                            <span onClick={() => navigate(`/banque/page/${pageNumber - 1}`)} > {pageNumber - 1} </span>
                                                        )
                                                    }
                                                    {
                                                        pageNumber && (
                                                            <span className={pageNumber == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/page/${pageNumber}`)}>
                                                                {pageNumber}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (pageNumber + 1 <= pages) && (
                                                            <span className={pageNumber + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/page/${pageNumber + 1}`)}>
                                                                {pageNumber + 1}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (pageNumber === 1) && (
                                                            <span onClick={() => navigate(`/banque/page/${pageNumber + 2}`)}>{pageNumber + 2}</span>
                                                        )
                                                    }
                                                    {
                                                        (pageNumber + 1 !== pages - 2) && (
                                                            <span>{'....'}</span>
                                                        )
                                                    }
                                                    <span onClick={() => navigate(`/banque/page/${pages - 1}`)} className={pages - 1 == page ? 'page-number-active' : ''}>
                                                        {pages - 1}
                                                    </span>
                                                    <span onClick={() => navigate(`/banque/page/${pages}`)} className={pages == page ? 'page-number-active' : ''}>
                                                        {pages}
                                                    </span>
                                                    <span onClick={() => navigate(`/banque/page/${pages}`)} > Dernier </span>
                                                    <span onClick={onclickNext} >
                                                        <i className="fa-solid fa-angle-right"></i>
                                                    </span>
                                                </div>
                                            )
                                    }
                                </div>
                            ) : ""
                        }
                        {/* End Pagination */}



                    </div>
                </div>
            </div>

            <DossierBanqueModal hash={hash} idModal={'dossierBanqueModal'} check={check} setCheck={setCheck} />
            <DossierGarantieModal hash={hash} idModal={'dossierGarantieModal'} check={check} setCheck={setCheck} />
            <VersBanqueModal hash={hash} idModal={'versBanqueModal'} check={check} setCheck={setCheck} />

        </div>
    );
}



export default HomePage